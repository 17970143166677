import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThemeProvider from './theme';
import './index.css';

const RedirectToWF = lazy(() => import('./handlers/RedirectToWF'));
const ResolveSlug = lazy(() => import('./handlers/ResolveSlug'));
const ResolveGroupSlug = lazy(() => import('./handlers/ResolveGroupSlug'));
const ProcessInvitation = lazy(() => import('./handlers/ProcessInvitation'));
const ProcessQRCode = lazy(() => import('./handlers/ProcessQRCode'));
const VerifyReview = lazy(() => import('./handlers/VerifyReview'));

const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const UnsubscribeEmailPage = lazy(() => import('./pages/UnsubscribeEmailPage'));
const UnsubscribeSMSPage = lazy(() => import('./pages/UnsubscribeSMSPage'));

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ThemeProvider>
    <BrowserRouter>  
      <Suspense fallback={<></>}>
        <Routes>
          <Route path="/" element={<RedirectToWF />} />
          <Route path="/invite/by-:type/:criteria" element={<ProcessInvitation />} />
          <Route path="/verify/:id/:hash" element={<VerifyReview />} />
          <Route path="/unsubscribe/email" element={<UnsubscribeEmailPage />} />
          <Route path="/unsubscribe/sms" element={<UnsubscribeSMSPage />} />
          <Route path="/write/*" element={<ResolveSlug writePage={true} />} />
          <Route path="/qrcode/:id" element={<ProcessQRCode />} />
          <Route path="/group/*" element={<ResolveGroupSlug />} />
          <Route path="/*" element={<ResolveSlug writePage={false} />} />
          <Route path="*" element={<ErrorPage content="404 Not Found" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  </ThemeProvider>
);